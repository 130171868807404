@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic&family=Noto+Kufi+Arabic:wght@400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;900&display=swap');

body {
  overflow-x: hidden;
  text-align: left;
  padding-bottom: 0;
  background-color:#f3f3f3;
  background-size:cover;
  background-position: center;
  margin-bottom: 4rem;
}



/* Modal */

div.modal-content {
  padding:2rem;
}

span.close {
  user-select: none;
  font-family: Montserrat;
  font-size: 2.5rem;
  height: 10%;
  width: 10%;
  margin-bottom: 2rem;
}

.modal {
  text-align: center;
  display: flex;
  flex-basis: auto;
  height: 60%;
  width: 80%;
  margin: 50% 25%;
  margin-top: 2rem;
  display:flex
}

.rtl-layout {
  direction: rtl;
  text-align:right;
}

.ltr-layout {
  direction: ltr;
  text-align:left;
}

.btn-dark {
  background-color: #121212;
  border-color:#bbb;
}


.row {
 margin-top:2rem;
 margin-bottom:2rem
}


.question {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  border-bottom-style: solid;
  border-color:#ddd;
  border-width: 1px;
  padding:1rem;
  border-radius: 1rem;
  background-color: #ececec;
  margin: 4px;
}

.question p {
  display: inline-block;
}

p {
  color:#121212;
}

strong {
  font-family:monospace, 'Noto Kufi Arabic';
  font-size:larger;
  margin-bottom: 0;
}


h1 {
  font-family:  Orbitron, 'Noto Kufi Arabic';
  font-weight: 900;
  font-size: 3.5rem;
  line-height: 1.5;
  color: #121212;
  text-align: left;
}

@media (max-width:1028px) {
  h1 {
    text-align:center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
}

h2 {
  font-family: Montserrat, 'Noto Kufi Arabic';
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #121212;
  text-align: center;
}


.index-btns {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color:#0000003b;
  border-radius: 32px;
  padding: 1.2rem;
}

.index-btns button {
  margin: 2% 3% 2.5% 0;
  width:200px;
}

@media (max-width:1028px) {
  .index-btns {
    text-align:center;
  }
}

.Download-button {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
  margin: 5% 3% 5% 0;
  font-family: Montserrat, 'Noto Kufi Arabic';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;
  border-radius: 10px;
}

i:hover {
  color:#f3f3f3;
}

.nav-brand {
  margin-top: 1%;
  margin-bottom: 1%;
}

.navbar {
  padding: 0;
}

@media (max-width:1028px) {
 
  .nav-item button {
  display: inline;
  padding: 0;
  width:100%
  }
}

.nav-item {
  padding: 0 0 0 8px;
  text-align: center;

}

.help-link {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}

@media (max-width:1028px) {
  .help-link {
    display: inline-block;
    width:100%;
    margin-top: 2rem;
  }  
}

@media (max-width:576px) {
  .help-link {
    width:30%;
    margin-top: 0;
  }  
}

.nav-link {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  font-family: Orbitron, 'Noto Kufi Arabic';
  font-weight: 500;
  font-size: 1.2rem;
  background-color: #ddd;
  margin-top: 8px;
  border-bottom: #fff;
  border-radius: 8px;
  border-width: 1px;

}

@media (max-width:576px) {
  .nav-link {
    font-size: 1rem;
  }
  .lang-switcher {
    margin-top:0.5rem
  }
}

.nav-link.active {
  border-style: solid;
  border-color: #121212;
}

.container-fluid {
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 3% 8% 3% ;
}


/* Form style */
form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  margin-bottom: 120px;
  display: grid;
  margin-top: 20px;
  width: 500px;
}

form h2 {
  color:#000000;
  font-weight: bold;
  margin-bottom: 2rem;
  border-bottom-style: solid;
  border-color: #ddd;
}


label {
  width: 100%;
  font-family: Orbitron, 'Noto Kufi Arabic', sans-serif;
  display: block;
  margin-bottom: 8px;
  color: #111;
  font-size: 16px;
}

input,
select,
textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

select {
  appearance: none;
}

.submit {
  background-color: #4caf50;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit:hover {
  background-color: #45a049;
}


/* Card styles */

.events {
  display: block;
  text-align: center;
  border-color: #fff;
}

.events a {
  margin-top: 1rem;
}

@media (min-width:768px){
  .last-card {
    margin-left: 25%;
  }
  
}

.card {
  height: max-content;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #ddd;
  border-radius: 1.2rem;
  text-align: right;
  border-style: solid;
  border-color: #bbb;
  padding: 2px;
}

.card-body {
  padding:6px;
}

.card-title {
  font-family: Orbitron, 'Noto Kufi Arabic';
  font-weight: 400;
  display: inline-block;
  border-bottom-style:solid;
  border-color: #bbb;
  color:#121212
}

.card-context {
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  color: #161616;
}

.card-img-top {
  border-radius: 1.2rem;
  max-width: fit-content;
  max-height: fit-content;
}

@media (max-width:1028px) {
  .card {
    max-width: fit-content;
  }
}


/* breadcrumbs */
.breadcrumbs {
  max-width: 1200px;
  margin: 20px auto;
}
.breadcrumbs > *{
  display: inline-block;
  margin-right: 10px;
  color:#007bff;
}
.breadcrumbs .crumb:after {
  content: '>';
  margin-left: 10px;
}
.breadcrumbs .crumb:last-child:after {
  display: none;
}
.breadcrumbs .crumb a {
  color: #121212;
  font-size: large;
}


@media (max-width:1018px) {
  form {
    width: 500px;
    display:grid;
    align-items: center;
    margin:20px auto;
  }
}

@media (max-width:576px) {
  form {
    width:320px;
    margin-top: 20px;
    margin-bottom: 8rem;
  }
  form h1 {
    font-size: xx-large;
  }
}

label {
  text-align: start;
  display: inline;
  margin-bottom: 8px;
}

input,
select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  box-sizing: border-box;
}

input[type="radio"] {
  margin-right: 5px;
}

#socialMediaLinkContainer {
  display: none;
}

.submit {
  display: block;
  width: 50%;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  font-weight: 600;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#successMessage {
  background-color: #28a745;
  color: #fff;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}

p.error-message {
  color: red;
  margin-top: 5px;
}

/*Checkbox*/

.checkbox-container span {
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  font-size:medium;
  font-weight: 400;
  color: #121212;
}

.checkbox-container:hover span {
  margin-left: 0;
}

.checkbox-container:hover {
  scale: 1.05;
  background-color: #ddd;
  transition: 0.5s;
}

.checkbox-container {
  display: flex;
  padding: 12px;
  align-items: center;
  width: 60%;
  border-radius: 10px;
  border-style:solid;
  border-width:1px;
  border-color: #bbb;
}

@media (max-width:576px){
  .checkbox-container {
    width:100%
  }
}

.checkbox-label {

  font-size: 1rem;
  flex-grow: 1;
}

.checkbox-input {
  margin:4px 10px 4px 8px;
  height: 18px;
  width: 15px;
}

/*Book styles*/

.book-row {
  border-style: solid;
  border-width: 1px;
  padding: 1rem;
  border-color: #bbb;
}

.book-img {
  width: 100%;
  margin: 0;
  border-radius: 32px;
}

@media (max-width:1028px) {
  .book-img {
    height: 94%;
  }
}

.book-txt h5 {
  color: #121212;
  display: inline;
  font-weight: 700;
}

.book-txt p {
  font-family: Montserrat, 'Noto Kufi Arabic', sans-serif;
  margin: 1px 0 12px;
  color: #161616;
}


.book-txt h1 {
  text-align: center;
}

/* Store */

.category-heading {
  font-family:Montserrat;
  margin-top: 2rem ;
  margin-bottom: 0;
  font-weight: 400;
  display: inline-block;
  width: 100%;
  text-align: center;
  border-width: 2px;
  border-top-style: solid;
  border-radius: 6rem;
  border-color: #ddd;
}


/* Slick */

.carousel {
  padding:0;
  width: 85vw;
}

.card-box {
  padding: 0;
  display: inline !important;

}

.slick-initialized .slick-slide {
  padding: 0 4px;
}

.slick-arrow {
  z-index: 1;
  height: 32px;
  width: 32px;
  background-color:#a9a9a9;
  border-radius: 50%;
}

.category-button {
  width: 100%;
  border-radius: 2rem;
  border-style:none ;
  border-bottom-style:solid ;
  border-color: #ddd;
  border-width: 2px;
}

.category-button:hover {
  color: #000000;
  background-color: #f3f3f3;
}

.fa-arrow-up-right-from-square {
  transform: rotate(270deg);
}

.add-to-cart {
  display: inline;
  width: 100%;
  border-radius: 0.8rem;
}

.shoe-icon {
  height: 32px;
  width: 64px;
}